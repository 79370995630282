import { toaster } from '@smst/ui'
import { nextTick, ref } from 'vue'
import { useMutation } from 'vue-query'

import { apiClient } from '@/api'
import type {
  LoginResult,
  TwoFactorResult,
} from '@/api/__generated__/api.schema'
import { setAuthTokens } from '@/api/authTokens'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { RouteNames } from '@/routeNames'
import { router } from '@/router'
import { getErrorMessage } from '@/utils/errors'
import { ProfileData } from '@/utils/profile/profile'

import { clearErrorCode, setErrorCode } from './AuthCaptcha.utils'
import { setCodeValid } from './components/Generate/Generate.utils'

export const useLogin = () => {
  const tSuccess = useComponentI18n('login')
  const resetConfirmation = ref(false)
  const confirmation = ref<TwoFactorResult | undefined>()

  // # ошибки при вводе кода их смс #
  // 113 время жизни сессии закончилось - 30 минут - после ввода логина и пароля
  // 114 неверно введен код
  // 115 много раз подряд введено неверно код - все сначала
  // 116 время жизни кода из смс закончилось - нужен новый - можно сразу новый давать запрашивать
  const resetForm = async () => {
    resetConfirmation.value = true
    await nextTick()
    resetConfirmation.value = false
  }
  const handleErrorCode = async (err: unknown) => {
    if (err?.code === 113 || err?.code === 115) {
      errorConfirm.value = ''
      await resetForm()
      confirmation.value = undefined
      toaster.error(getErrorMessage(err))
    }
    if (err?.code === 114) {
      errorConfirm.value = ''
      await resetForm()
      toaster.error(getErrorMessage(err))
    }
    if (err?.code === 116) {
      errorConfirm.value = ''
      await resetForm()
      toaster.error(getErrorMessage(err))
      setCodeValid(false)
    }
  }

  const auth = async (data: LoginResult) => {
    setAuthTokens({
      accessToken: data.accessToken,
      refreshToken: data.refreshToken,
    })
    ProfileData.deleteProfileData()
    await nextTick()
    void router.push({ name: RouteNames.Dashboard })
    clearErrorCode()
  }

  const {
    mutate: login,
    isLoading,
    error,
  } = useMutation(apiClient.auth.login_POST, {
    onSuccess: async ({ data }) => {
      if ('accessToken' in data) {
        await auth(data)
      } else {
        confirmation.value = data
        // 2FA code
        data.secreteCode && console.info(`2FA code: `, data.secreteCode)
      }
    },
    onError: (err) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      void setErrorCode(err?.code)
    },
  })

  const {
    mutate: confirm,
    isLoading: isLoadingConfirm,
    error: errorConfirms,
  } = useMutation(apiClient.auth.validateSecreteCode_POST, {
    onSuccess: async ({ data }) => {
      if ('accessToken' in data) {
        void auth(data)
        toaster.success(tSuccess('success'))
      } else {
        await resetForm()
      }
    },
    onError: async (err) => {
      await handleErrorCode(err)
      throw new Error(err)
    },
  })

  const errorConfirm = ref(errorConfirms)
  return {
    login,
    isLoading,
    error,
    confirmation,
    confirm,
    isLoadingConfirm,
    errorConfirm,
    resetConfirmation,
  }
}
